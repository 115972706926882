<!-- eslint-disable vue/camelcase -->
<template>
  <div>
    <TitleWrapper
      :filter-option="false"
      title="GRADING_DETAILS"
      tooltip-title="GRADING_DETAILS"
      :display-breadcrumb="true"
    ></TitleWrapper>
    <div v-if="isLoading">
      <Loader :content="true" />
    </div>
    <div v-else class="w-full mt-5">
      <DetailCard :details="cardDetials" custom-classes="flex-col" />
      <div>
        <Heading heading="Scale" heading-size="pb-0 text-xl" />
        <TableWrapper>
          <THead>
            <TRHead>
              <TH v-for="(head, index) in tableHeaders" :key="index">
                {{ head }}
              </TH>
            </TRHead>
          </THead>
          <TBody>
            <TRBody v-for="(grade, index) in structureDetails.grading_scales" :key="index">
              <TD>
                {{ grade.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
              </TD>
              <TD>
                <div class="font-inter font-normal text-sm cursor-pointer w-64">
                  <div class="flex items-center">
                    <div class="flex items-center gap-2">
                      <Tooltip
                        :term="greaterThanEqual.tooltipTitle"
                        :dynamic-message="true"
                        custom-tooltip="-ml-36"
                        color="primary-purple-600"
                      />
                      <div>
                        {{ greaterThanEqual.title }}
                      </div>
                      <div>{{ grade.min }}%</div>
                    </div>
                    <pre class="font-bold ml-2">-</pre>
                    <div class="flex items-center gap-2">
                      <Tooltip
                        :term="index ? lessThan.tooltipTitle : lessThanEqual.tooltipTitle"
                        :dynamic-message="true"
                        custom-tooltip="-ml-36"
                        color="primary-purple-600"
                      />
                      <div>
                        {{ index ? lessThan.title : lessThanEqual.title }}
                      </div>
                      <div>{{ grade.max }}%</div>
                    </div>
                  </div>
                </div>
              </TD>
              <TD>
                {{ grade.scale || GENERAL_CONSTANTS.NOT_APPLICABLE }}
              </TD>
            </TRBody>
            <TRBody>
              <TD>
                {{ structureDetails.default_grade_title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
              </TD>
              <TD>
                <div>
                  <div class="flex items-center gap-2.5">
                    <Tooltip
                      :term="lessThan.tooltipTitle"
                      :dynamic-message="true"
                      custom-tooltip="-ml-36"
                      color="primary-purple-600"
                    />
                    <div>
                      {{ lessThan.title }}
                    </div>
                    <div>{{ structureDetails.default_less_than }}%</div>
                  </div>
                </div>
              </TD>
              <TD>
                {{ structureDetails.default_scale || GENERAL_CONSTANTS.NOT_APPLICABLE }}
              </TD>
            </TRBody>
          </TBody>
        </TableWrapper>
      </div>
    </div>
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import { mapActions } from 'vuex'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import Loader from '@components/BaseComponent/Loader.vue'
import DATE_TIME_CONSTANT from '@src/constants/date-time-constants.js'
import scrollMixin from '@src/mixins/scroll-mixin'
import Heading from '@components/Heading.vue'
import DetailCard from '@/src/components/UiElements/DetailCard.vue'
import tooltipMixin from '@src/mixins/components/tooltip-mixin.js'
import Tooltip from '@src/router/views/landingPage/TooltipIcon.vue'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
export default {
  components: {
    TitleWrapper,
    Loader,
    Heading,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
    DetailCard,
    Tooltip,
  },

  mixins: [scrollMixin, tooltipMixin],
  data() {
    return {
      GENERAL_CONSTANTS,
      DATE_TIME_CONSTANT,
      structureDetails: {},
      tableHeaders: ['Title', 'Grade Range', 'Scale'],
      isLoading: false,
      requestLogs: [],
      cardDetials: {},
      greaterThanEqual: { title: 'GTE', tooltipTitle: 'Greater than equal to' },
      lessThanEqual: { title: 'LTE', tooltipTitle: 'Less than equal to' },
      lessThan: { title: 'LT', tooltipTitle: 'Less than' },
    }
  },
  page: {
    title: 'Non GPA Grading Structure',
  },
  computed: {
    currentGradingStructureId() {
      return this.$route.params.id
    },
  },
  mounted() {
    this.getDetails()
  },
  methods: {
    async getDetails(range) {
      this.isLoading = true
      let id = this.currentGradingStructureId
      const [response, error] = await this.getGPAStructureDetails(id)
      this.structureDetails = response?.data
      this.cardDetials = {
        title: this.structureDetails.title,
        description: this.structureDetails.description,
      }

      this.isLoading = false
    },
    ...mapActions('exams', ['getGPAStructureDetails']),
  },
}
</script>
